import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

const PrivacyNotice = () => {
    return (
        <Layout lang={'cy'} langURL={'/cy/privacy-notice/'}>
            <Seo title={'Privacy Notice'} description={'The complete Privacy Notice for the Teacher\'s hub'} lang={'en-GB'} />
            <main id={'content'} className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} className={'pb-5'}>
                            <h1 className={'content--h1'}>Principality Building Society Privacy Notice</h1>
                            <h2>Our contact details</h2>
                            <p>Name: Principality Building Society</p>
                            <p>Phone Number: 0330 333 4000</p>
                            <p>E-mail: <a href={'mailto:sharedmailbox.digitalteam@principality.co.uk'} className={'content--home--text--link'}>SharedMailbox.DigitalTeam@principality.co.uk</a></p>
                            <h2>The type of personal information we collect</h2>
                            <p>We currently collect and process the following information:</p>
                            <ul>
                                <li>Personal identifiers, contacts and characteristics (for example, age range, school name, town and email address)</li>
                            </ul>
                            <h2>How we get the personal information and why we have it</h2>
                            <p>Most of the personal information we process is provided to us directly by you for one of the following reasons:</p>
                            <ul>
                                <li>To keep you updated with information relating to Dylan Teacher hub, this can include any changes to or additional content that might be of interest to you</li>
                            </ul>
                            <p>We use the information that you have given us in order to keep you informed, as requested, on any updates to Dylan’s Teacher Squad website and offering.</p>
                            <p>Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are:</p>
                            <p><strong>(a) Your consent. You are able to remove your consent at any time. You can do this by contacting 0330 333 4000</strong></p>
                            <p><strong>(b) We have a legitimate interest.</strong></p>
                            <h2>How we store your personal information</h2>
                            <p>Your information is securely stored within our email system with restricted access.</p>
                            <p>We keep your email address for as long as it is relevant or you unsubscribe. Once you unsubscribe we will remove your details from subsequent mailing lists and dispose of your information by deleting your details from the email system within 12 months.</p>
                            <h2>Your data protection rights</h2>
                            <p>Under data protection law, you have rights including:</p>
                            <p><strong>Your right of access</strong> - You have the right to ask us for copies of your personal information.</p>
                            <p><strong>Your right to rectification</strong> - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</p>
                            <p><strong>Your right to erasure</strong> - You have the right to ask us to erase your personal information in certain circumstances.</p>
                            <p><strong>Your right to restriction of processing</strong> - You have the right to ask us to restrict the processing of your personal information in certain circumstances.</p>
                            <p><strong>Your right to object to processing</strong> - You have the right to object to the processing of your personal information in certain circumstances.</p>
                            <p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</p>
                            <p>Please contact us at <a href={'mailto:dpo@principality.co.uk'} className={'content--home--text--link'}>DPO@principality.co.uk</a> if you wish to make a request.</p>
                            <h2>How to complain</h2>
                            <p>If you have any concerns about our use of your personal information, you can make a complaint to us at 0330 333 4000 or by writing to us at Principality Building Society, PO Box 89, Queen Street, Cardiff CF10 1UA.</p>
                            <p>You can also complain to the ICO if you are unhappy with how we have used your data.</p>
                            <p>The ICO’s address:</p>
                            <p>
                                Information Commissioner’s Office<br />
                                Wycliffe House<br />
                                Water Lane<br />
                                Wilmslow<br />
                                Cheshire<br />
                                SK9 5AF
                            </p>
                            <p>Helpline number: 0303 123 1113</p>
                            <p>ICO website: <a href={'https://www.ico.org.uk'} rel={'noreferrer'} className={'content--home--text--link'} target={'_blank'}>https://www.ico.org.uk</a></p>
                        </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    )
};

export default PrivacyNotice;